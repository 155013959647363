import React from 'react'
import PropTypes from 'prop-types'

import FaqGlossaryTopComponent from '../faq-glossary-top/faq-glossary-top.component'
import ReferencesComponent from '@components/references/reference.component'
import GlossaryIndex from '../faq-glossary/glossary-terms-index'
import  { glossaryReferences } from '../faq-glossary/references'
import './faq-glossary-item.component.scss'

const FaqGlossaryItem = ({ term }) => {

  // let faqAnswerKey = match.params.faq
  let glossaryTermKey = term
  let topContent = null

  const getFaqGlossaryContent = (
    indexToUse,
    itemKey,
    referenceListToUse,
  ) => {
    let foundItem = null
    let index = null
    let previousItem = null
    let nextItem = null
    let content = null

    for (let i = 0; i < indexToUse.length; i++) {
      let faqGlossaryItem = indexToUse[i]

      if (faqGlossaryItem.url.toLowerCase() === itemKey.toLowerCase()) {
        foundItem = faqGlossaryItem
        index = i
        break
      }
    }

    try {
      previousItem = index !== 0 ? indexToUse[index - 1] : indexToUse[indexToUse.length - 1]
    } catch (err) {
      previousItem = null
    }

    try {
      nextItem = index !== indexToUse.length - 1 ? indexToUse[index + 1] : indexToUse[0]
    } catch (err) {
      nextItem = null
    }

    content = {
      title: foundItem.faq,
      body: foundItem.component,
      next: null,
      previous: null,
      referenceList: foundItem.reference || referenceListToUse,
      topLinkContent: foundItem.topLinkContent
    }

    if (nextItem) {
      content.next = nextItem.url
    }

    if (previousItem) {
      content.previous = previousItem.url
    }

    return content
  }
  // if (faqAnswerKey) {
  //   topContent = getFaqGlossaryContent(FaqIndex, faqAnswerKey, 'FAQ', [], 'faq', 'Narcolepsy FAQs')
  // } else if (glossaryTermKey) {

    topContent = getFaqGlossaryContent(
      GlossaryIndex,
      glossaryTermKey,
      glossaryReferences
    )
  // }

  if (topContent) {
    return (
      <div>
        <div className="narcolepsy-answer-wrapper primary-container">
          <FaqGlossaryTopComponent faqTopContent={topContent} />
        </div>
        <ReferencesComponent referenceList={topContent.referenceList} />
      </div>
    )
  } else {
    return <Redirect to="/404" />
  }
}

FaqGlossaryItem.propTypes = {
  term: PropTypes.string.isRequired
}

export default FaqGlossaryItem
