import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { win } from '@src/app-utils'

import SiteLink from '@components/SiteLink'
import './faq-glossary-top.component.scss'

class FaqTopComponent extends React.Component {
  constructor(props) {
    super(props)
    if (win) {
      this.state = {
        screenWidth: window.innerWidth,
      }
    }

    this.handleWindowResize = this.handleWindowResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize() {
    win && this.setState({ screenWidth: window.innerWidth })
  }

  render() {
    let titleList = this.props.faqTopContent.title

    if (win && window.innerWidth < 500) {
      titleList = titleList.split('/')
      titleList = (
        <div>
          <span className={win && window.innerWidth < 500 && titleList[0] === ' Hypercholesterolemia' ? 'term--small-font' : ''}>
            {titleList[0]}
            {titleList.length > 1 && '/'}
          </span>
          <br />
          <span>{titleList[1]}</span>
        </div>
      )
    }

    return (
      <div className="faq-top-wrapper">
        <div className="faq-top-text">
          <SiteLink className="to-faqs" to="/resources/glossary-of-terms/">
            <FontAwesomeIcon icon="angle-left" />
            <h1 className="main-page-header">{titleList}</h1>
          </SiteLink>
          <div className="faq-top-body">{React.createElement(this.props.faqTopContent.body)}</div>
          <div className="button-wrapper">
            {this.props.faqTopContent.previous && (
              <SiteLink
                className="button-previous"
                to={`/resources/glossary-of-terms/${this.props.faqTopContent.previous}`}
              >
                <FontAwesomeIcon icon="angle-left" />
                <div className="button-text">Previous</div>
              </SiteLink>
            )}
            {this.props.faqTopContent.next && (
              <SiteLink
                className="button-next"
                to={`/resources/glossary-of-terms/${this.props.faqTopContent.next}`}
              >
                <FontAwesomeIcon icon="angle-right" />
                <div className="button-text">Next</div>
              </SiteLink>
            )}
          </div>
        </div>
      </div>
    )
  }
}

FaqTopComponent.propTypes = {
  faqTopContent: PropTypes.object.isRequired,
}

export default FaqTopComponent
